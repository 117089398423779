import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Workflows`}</h1>
    <p>{`A workflow is an automated system of operations that ensures that work moves through the system in a timely manner and is processed correctly by the appropriate task.
CoreMedia workflows serves as a solution that can monitor, evaluate and maintain tasks and processes in real time. By defining events and triggers, the corresponding steps can be activated when a certain event occurs. `}</p>
    <h2>{`Workflow scope`}</h2>
    <h3>{`Visitor`}</h3>
    <p>{`The focus of a visitor workflow is the visitor himself. The only requirement is the presence of a CoreMedia visitor, regardless of time, session, channel or touch point.  `}</p>
    <p>{`For each visitor, an instance of any active visitor workflow is created. `}</p>
    <h3>{`Session`}</h3>
    <p>{`A session workflow is only valid for the time of a CoreMedia session. In a session workflow, there is always one CoreMedia visitor. For each session, an instance of any active session workflow is created. `}</p>
    <h3>{`Outbound or outbound campaign`}</h3>
    <p>{`An outbound workflow enables the tracking and automation of each existing participant
(record) in an outbound campaign such as email marketing, SMS or voice broadcast.
An instance of an active outbound workflow is created for each record in the associated
contact list.`}</p>
    <h3>{`Voice contact`}</h3>
    <p>{`A voice contact workflow enables tracking and automation of every existing CoreMedia voice contact.
For each voice contact, an instance of any active voice contact workflow is created.`}</p>
    <h2>{`Workflow elements`}</h2>
    <p>{`The workflow elements are split into:`}</p>
    <ul>
      <li parentName="ul">{`Context Data: It allows us to save data on variables, pass this information along the
workflow and use it when necessary.`}</li>
      <li parentName="ul">{`Platform Events: login, logout, timer,...`}</li>
      <li parentName="ul">{`Workflows actions: add to a contact list, make a call, call an URL,...`}</li>
      <li parentName="ul">{`Workflow conditions: decision steps.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Elements can be removed and duplicated.`}</p>
    </blockquote>
    <h3>{`Events`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This node waits for a visitor to start a session. When the session starts, the event is captured by the respective workflow. The node needs to be configured based on the client's request. An example: The client needs to launch an SMS campaign when there is access to a particular external campaign from a particular device. We use this node to obtain the login information, followed by an add to contact list node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This node is the opposite of the session start and is used to check which session ended. In this case, the node must also be configured to listen to the event correctly. An example: Let's imagine that when a session ends and we've been on a trolley page, we need to send a trolley recovery email. It is using this node in a Workflow that we have this action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This node captures page request events. It is possible for this node to listen to the request for certain pages, as long as it is configured. An example: Imagine that when a visitor accesses a particular client page, they receive an SMS indicating that they have just won a voucher. We use this node to listen for page requests.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action on element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where you can configure and listen to certain actions on onsite elements configured on the CoreMedia Engagement Cloud platform. An example: Let's imagine that we need to configure that a particular visitor who clicks on a close element stays in a refusal segment. We use this node to listen for this action of closing the element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Change of outbound status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When a record changes status in an outbound campaign, this node is able to retrieve its status. It is also possible to configure to listen to a particular status in a particular campaign.An example: When an email is clicked, we need to launch a new email to the visitor. Using this node is possible.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When we need to obtain the action of an outbound record, such as sending an SMS. An example: When we send a SMS and it is received by the visitor, we send an event in order to create a new event in the future on the database.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When a particular field on the contact form changes value, this node obtains all the changes from the visitor form. An example: Let's imagine that we need to find out whether a visitor belongs to a particular customer group, a group that is filled in a field on the contact form and has the value X. All we need to do is configure the node to listen for this information.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When there is a need to be listen for the moment when a type of contact is requested. This node allows a lot of configurations as the skill, the request page, the type of call, which campaign requested the contact, etc. An example: We need to notify a customer's CRM when the action of requesting the contact is carried out. We use this node to listen for the contact request and then send the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ended contact attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node that lets you know when a contact attempt has ended, so you can know when contact attempts are finalized. This node allows a lot of configurations as the skill, the request page, the type of call, which campaign requested the contact, etc. An example: We need to notify a client's CRM when a contact attempt has been completed, so that the client realizes when a record has been answered. We use this node to listen for the answered attempt and then send the notification to the client.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ended contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node that lets you know when a contact has ended, i.e. there is no longer any possibility of receiving a contact attempt, so you can know when contacts are finalized and, for example, there has been no contact with the visitor. This node gives you the possibility of numerous configurations, from the skill, request page, type of call, which campaign requested the contact, etc.  An example: We need to notify a client's CRM when contact is finalized and there has been no conversation (unsuccessful contact), so that the client can see the volume of unsuccessful calls. We use this node to listen for this action and then send the notification to the partner.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Webcare event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node that lets you know when an event/signal from the CoreMedia Engagement Cloud platform happens. This way we can tell when events and certain properties happen. An example: We need to send an email when a product purchase takes place. When the basket is checked out and the client sends the event to the CoreMedia Engagement Cloud platform, workflows can capture that moment, and then move on to the node for adding a record to a contact list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Timer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node that provides the possibility of waiting and moving forward when a certain amount of time has elapsed. You can set it by time, days, minutes, hours, after a number of days, a specific date or even the next occurrence of a day of the week. An example: We need to wait 30 minutes before moving on to the next node. We configure this timer node with this timer.`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Session`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Voice contact`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action on element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Change of outbound status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ended contact attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ended contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Webcare event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Timer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Actions`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound contact info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This action allows us to obtain the information of an outbound contact and use that information in a future node. To do this, we just need to have the information initialized in the node, so that Workflow can start knowing these variables. Example: Let's imagine we have an SMS workflow or even an outbound campaign. A client might need the information you've added to that record, such as customizable parameters, email, telephone, external id. This node allows the information to be kept in context and used in any node in the future.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove from contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This node is used to add or remove records from a contact list (contact lists are usually associated with outbound campaigns). It depends on the configuration of the node to carry out all the actions. It is also possible to clone the information of the new outbound record, based on a voice contact (you just need to indicate the CID/ID of the contact to be cloned.). An example: The client wants an SMS to be sent at the end of an unsuccessful call, trying to get a new inbound contact. For this we will have a call completion node, configured for failures. Then an add or remove node from a contact list using telephone number as the input field. Then we have to have the contact list associated with an SMS campaign, which is always active and sends the corresponding SMS every time a new record is entered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove from segment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where you can add a visitor to a segment. To do this, they must have a visitor_id, any visitor from an outbound campaign or via an inbound call should not be used in this node because they have no associated visitor. An example: The client wants a visitor who accesses the login page and logs in to be included in the Loyalty segment. To do this, validate that the visitor has a FID (identified cookie) and then add them to the Loyalty segment.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove Lead score`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where we can influence a certain Lead Score. In this node, we have to configure the lead score to be influenced and indicate the value to be increased.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where we configure a GET or POST request to a public URL. This service is normally used with customized URLs that can perform actions that are not available in the current nodes. It is also possible, if a response exists in JSON format, to use fields from this response in actions on new workflow nodes (this is called context information). An example: The client wants to make notifications to services in his CRM, we use this node to implement it. We can also use the response to create a new record for an SMS campaign (for example).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Send event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where we configure the sending of a CoreMedia signal, we have to indicate the event and then configure the event's properties. Note that this node must have an associated visitor, otherwise the event will not be launched. An example: Let's imagine that the client wants to have a list of people who were impacted by the previous node, all we have to do is launch this node and its configuration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add contact attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node for adding a new attempt to a voice contact record. This node allows you to add the record to an available slot if the voice service is out of schedule. (Note: Node only available in ) An example: Normally this service is used to postpone retries in call recovery systems. Normally the out-of-hours option is to schedule it in the next available slot, so that when the service opens it can launch the calls.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`New call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where it is possible to launch a new call type 'W'. All you need is a valid telephone number and a call type W is automatically created. It also allows you to clone information from the original contact, such as the fields in the contact form. An example: We need to create a new type of call, to give less priority to contact attempts that fail after the 7th contact attempt. So we create a call type W to go to a different skill and have lower priority.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Single path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where we will have a single path, this node is used so that if a visitor is on several paths at the same time. It will always be valid on the path it is on. It only works in the workflow where it is used. An example: Let's imagine that we have a visitor who is on a divergent path where he can be present on both paths. Let's imagine that there is a cart checkout and we need to send an email to confirm the order. We need to make sure, before sending the email, that the visitor is only on this checkout path before the email is sent. That's why we use this node before sending the SMS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End workflow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node where all the instances present in worflow end. After this action in a workflow, there is no new evaluation. This is the end of the worflow. An example: When we have finished trying a contact, this node is placed. To make sure that there are no more attempts and evaluations in workflow.`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Session`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Voice contact`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound contact info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove from contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove from segment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add or Remove Lead score`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Send event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add contact attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`New call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Single path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End workflow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Conditionals`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Divergent (conditional paths)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node that allows us to provide logic in a workflow, where we can advance to certain paths if a certain variable is set to a certain value. It's a node very similar to a conditional node, i.e. it makes the condition to move to a certain path. An example: I want to move to a contact list if the call is of the inbound type. I use a conditional node to be able to differentiate between call types and, depending on the value, move to the indicated path.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Convergent (paths aggregator)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contrary to the divergent node, this is used for several paths to converge and follow a path from this node. An example: We need 2 nodes in a workflow to converge on a single path, so we use this node.`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Session`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Voice contact`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Divergent (conditional paths)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Convergent (paths aggregator)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      